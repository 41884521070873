
import { computed, defineComponent, ref, warn } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import { translate } from "@/core/helpers/translate";
import { useQuery } from "@/core/helpers/common-helper";
import { Action } from "vuex-module-decorators";
import ApiService from "@/core/services/ApiService";
import APP_INFO from "@/core/constants";
import { useApi } from "@/core/services/useApi";

export default defineComponent({
  name: "login",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const submitButton = ref<null | HTMLButtonElement>(null);

    //Create form validation object
    const login = Yup.object().shape({
      email: Yup.string()
        // .email()
        .required()
        .label("Email"),
      password: Yup.string()
        .required()
        .label("Password")
    });

    const { query } = useQuery();

    //Form submit function
    const onSubmitLogin = async values => {
      submitButton.value ? (submitButton.value.disabled = true) : null;
      submitButton.value?.setAttribute("data-kt-indicator", "on");

      // Clear existing errors
      store.dispatch(Actions.AUTH__LOGOUT);

      return store
        .dispatch(Actions.AUTH__LOGIN, values)
        .then(() => {
          //Disable button
          submitButton.value ? (submitButton.value.disabled = false) : null;
          //Deactivate indicator
          submitButton.value?.removeAttribute("data-kt-indicator");

          // if (query.value.redirect_to) {
          //   return router.replace(query.value.redirect_to);
          // }
          return router.replace({ name: "app.dashboard" });
        })
        .catch(() => {
          //Disable button
          submitButton.value ? (submitButton.value.disabled = false) : null;
          //Deactivate indicator
          submitButton.value?.removeAttribute("data-kt-indicator");
          Swal.fire({
            text: store.getters.getErrors,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Try again!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger"
            }
          });
        });
    };

    return {
      onSubmitLogin,
      login,
      submitButton,
      translate
    };
  }
});
